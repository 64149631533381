import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { SearchEmail } from '../../../components/search-email';
import { FormSectionHeader } from '../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// feature rate
import {
  dispatcherFields,
  carrierFleetFields,
  dispatcherInfoInitFields,
} from './settings';
//////////////////////////////////////////////////

const enhance = compose(
  withState('dispatcherInfo', 'setDispatcherInfo', ({ values }: Object) => R.pick(
    R.keys(dispatcherInfoInitFields),
    values,
  )),
  withHandlers({
    handleToggleDispatcherSection: (props: Object) => (expanded: boolean) => {
      const {
        values,
        setValues,
        dispatcherInfo,
        setDispatcherInfo,
      } = props;

      if (expanded) {
        const dispatcherKeys = R.keys(dispatcherInfoInitFields);

        setValues(R.omit(dispatcherKeys, values));

        setDispatcherInfo(R.pick(dispatcherKeys, values));
      } else {
        setValues(R.mergeRight(values, dispatcherInfo));
      }
    },
  }),
  pure,
);

const DispatcherSection = (props: Object) => {
  const {
    emails,
    isEditMode,
    fieldError,
    contactList,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    setIsValidEmails,
    searchEmailMargin,
    handleToggleDispatcherSection,
  } = props;

  return (
    <FormSectionHeader
      expanded={isEditMode}
      handleToggleSection={handleToggleDispatcherSection}
      title={G.getWindowLocale('titles:dispatch-info', 'Dispatch Info')}
    >
      <Box p={15} pt={0}>
        <Flex mb={15}>
          <Fieldset2
            {...G.getFormikProps(props)}
            fields={dispatcherFields}
          />
          <SearchEmail
            width={395}
            labelPl='5px'
            emails={emails}
            borderRadius='4px'
            labelFontSize={11}
            fieldError={fieldError}
            contactList={contactList}
            margin={searchEmailMargin}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setIsValidEmails={setIsValidEmails}
            fieldName={GC.FIELD_DISPATCH_EMAILS}
            labelColor={G.getTheme('colors.darkGrey')}
            borderColor={G.getTheme('colors.veryLightGrey')}
            title={G.getWindowLocale('titles:dispatcher-emails', 'Dispatcher Emails')}
          />
        </Flex>
        <Fieldset2
          {...G.getFormikProps(props)}
          fields={carrierFleetFields}
        />
      </Box>
    </FormSectionHeader>
  );
};

export default enhance(DispatcherSection);
