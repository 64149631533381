import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import {
  DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
  DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
} from '../../../helpers/options';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const dispatcherInfoInitFields = {
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_DISPATCH_NAME]: null,
  [GC.FIELD_DISPATCH_PHONE]: null,
  [GC.FIELD_DISPATCH_EMAILS]: null,
};

const carrierAssignmentInitFields = {
  [GC.FIELD_TRUCK]: '',
  [GC.FIELD_TRAILER]: '',
  [GC.FIELD_CARRIER_NAME]: '',
  [GC.FIELD_CARRIER_SCAC]: '',
  [GC.FIELD_PRIMARY_DRIVER]: '',
  [GC.FIELD_SECONDARY_DRIVER]: '',
  [GC.FIELD_CARRIER_USDOT_NUMBER]: '',
  [GC.FIELD_PRIMARY_DRIVER_PHONE]: null,
  [GC.FIELD_SECONDARY_DRIVER_PHONE]: null,
};

const marginViolationInitFields = {
  [GC.FIELD_MARGIN_VIOLATION_NOTE]: null,
  [GC.FIELD_MARGIN_VIOLATION_REASON]: null,
};

const carrierRateInitFields = {
  ...dispatcherInfoInitFields,
  ...marginViolationInitFields,
  [GC.FIELD_MODE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_SERVICE_TYPE]: null,
  [GC.FIELD_SERVICE_DAYS]: null,
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: null,
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: null,
  [GC.FIELD_PREFIX_CARRIER_ASSIGNMENT]: carrierAssignmentInitFields,
};

const carrierOptionFields = [
  {
    name: GC.FIELD_GUID,
    collection: false,
  },
  {
    name: GC.FIELD_BRANCH_GUID,
    collection: false,
  },
  {
    name: GC.FIELD_CARRIER_NAME,
    collection: false,
  },
  {
    name: GC.FIELD_CARRIER_USDOT_NUMBER,
    collection: false,
  },
  {
    name: GC.FIELD_CARRIER_SCAC,
    collection: false,
  },
  {
    name: GC.FIELD_CARRIER_MC_NUMBER,
    collection: false,
  },
  {
    name: GC.FIELD_CARRIER_TERMINAL_NAME,
    collection: true,
  },
];

const dispatcherOptionFields = [
  {
    collection: true,
    name: GC.FIELD_USER_LOGIN_ID,
  },
  {
    collection: true,
    name: GC.FIELD_FIRST_NAME,
  },
  {
    collection: true,
    name: GC.FIELD_LAST_NAME,
  },
];

const CustomTotalComponent = ({ entity }: Object) => {
  const total = R.pathOr('', ['data', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_TOTAL], entity);
  const currency = R.pathOr('', ['data', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL, GC.FIELD_CURRENCY], entity);

  if (G.isNotNilAndNotEmpty(total)) {
    return (
      <Box
        p='4px 5px'
        fontWeight='bold'
        borderRadius='3px'
        width='max-content'
        bg={G.getTheme('common.linkColor')}
        color={G.getTheme('common.whiteTextColor')}
      >
        {G.getCurrencySymbol(currency)}{G.toFixed(total, 2)} {currency}
      </Box>
    );
  }

  return null;
};

const carrierRateSettings = {
  [GC.FIELD_TRANSPORTING_CARRIER]: {
    width: 150,
    name: 'titles:transporting-carrier',
    customComponent: (entity: Object) => R.path(
      ['data', GC.FIELD_TRANSPORTING_CARRIER, GC.FIELD_NAME],
      entity,
    ),
  },
  [GC.FIELD_NAME]: {
    width: 150,
    name: 'titles:carrier-name',
  },
  [GC.FIELD_CARRIER_CONTRACT_NAME]: {
    width: 150,
    name: 'titles:contract-name',
  },
  configAccountNumber: {
    width: 155,
    name: 'titles:integration-account-number',
  },
  [GC.FIELD_QUOTE_NUMBER]: {
    width: 150,
    name: 'titles:quote-number',
  },
  [GC.FIELD_MODE]: {
    width: 150,
    name: 'titles:mode',
    customComponent: (entity: Object) => R.path(
      ['data', GC.FIELD_PRICE, GC.FIELD_MODE, GC.FIELD_DISPLAYED_VALUE],
      entity,
    ),
  },
  [GC.FIELD_TOTAL]: {
    width: 150,
    name: 'titles:total',
    customComponent: (entity: Object) => (
      <CustomTotalComponent entity={entity} />
    ),
  },
  [GC.FIELD_CARRIER_MC_NUMBER]: {
    width: 150,
    name: 'titles:mc-number',
  },
  [GC.FIELD_CARRIER_USDOT_NUMBER]: {
    width: 150,
    name: 'titles:usdot-number',
  },
  [GC.FIELD_CARRIER_SCAC]: {
    width: 150,
    name: 'titles:scac',
  },
  [GC.FIELD_CARRIER_EQUIPMENTS]: {
    width: 250,
    name: 'titles:equipment',
    customComponent: ({ data }: Object) => {
      const equipments = R.path([GC.FIELD_PRICE, GC.FIELD_CARRIER_EQUIPMENTS], data);

      if (G.isNilOrEmpty(equipments)) return '';

      const text = R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_DISPLAYED_VALUE)),
      )(equipments);

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_CARRIER_SERVICE_TYPES]: {
    width: 250,
    name: 'titles:service-type',
    customComponent: ({ data }: Object) => {
      const serviceTypes = R.path([GC.FIELD_PRICE, GC.FIELD_CARRIER_SERVICE_TYPES], data);

      if (G.isNilOrEmpty(serviceTypes)) return '';

      const text = R.compose(
        R.join(', '),
        R.map(R.prop(GC.FIELD_DISPLAYED_VALUE)),
      )(serviceTypes);

      return (
        <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_TRANSIT_DAYS]: {
    width: 150,
    name: 'titles:transit-days',
    customComponent: R.pathOr('', ['data', 'price', GC.FIELD_TRANSIT_DAYS]),
  },
};

const carrierRateReport = {
  fields: [
    {
      width: 150,
      name: GC.FIELD_TOTAL,
    },
    {
      width: 150,
      name: GC.FIELD_NAME,
    },
    {
      width: 150,
      name: GC.FIELD_TRANSPORTING_CARRIER,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_CONTRACT_NAME,
    },
    {
      width: 150,
      name: 'configAccountNumber',
    },
    {
      width: 150,
      name: GC.FIELD_QUOTE_NUMBER,
    },
    {
      width: 150,
      name: GC.FIELD_MODE,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_SERVICE_TYPES,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_EQUIPMENTS,
    },
    {
      width: 150,
      name: GC.FIELD_TRANSIT_DAYS,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_MC_NUMBER,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_USDOT_NUMBER,
    },
    {
      width: 150,
      name: GC.FIELD_CARRIER_SCAC,
    },
  ],
};

const carrierInputWrapperStyles = {
  mr: 15,
  width: 190,
};

const dispatchedByStyle = {
  ...carrierInputWrapperStyles,
  mt: 15,
  width: 250,
  display: () => G.ifElse(
    G.hasNotAmousCurrentUserPermissions([PC.TEL_RATE_DISPATCH_BY_OVERWRITE_EXECUTE]),
    'none',
  ),
};

const getCarrierSectionFields = (isEditMode: boolean = false) => {
  const fields = [
    {
      type: 'asyncselect',
      errorPosition: 'static',
      label: ['titles:carrier-name'],
      handler: 'handleCarrierFilter',
      fieldName: GC.FIELD_CARRIER_NAME,
      optionGetter: 'handleGetCarrierOptions',
      inputWrapperStyles: carrierInputWrapperStyles,
      fieldPrefix: GC.FIELD_PREFIX_CARRIER_ASSIGNMENT,
    },
    {
      type: 'asyncselect',
      errorPosition: 'static',
      handler: 'handleCarrierFilter',
      label: ['titles:usdot-number'],
      optionGetter: 'handleGetCarrierOptions',
      fieldName: GC.FIELD_CARRIER_USDOT_NUMBER,
      inputWrapperStyles: carrierInputWrapperStyles,
      fieldPrefix: GC.FIELD_PREFIX_CARRIER_ASSIGNMENT,
    },
    {
      type: 'asyncselect',
      label: ['titles:scac'],
      errorPosition: 'static',
      handler: 'handleCarrierFilter',
      fieldName: GC.FIELD_CARRIER_SCAC,
      optionGetter: 'handleGetCarrierOptions',
      inputWrapperStyles: carrierInputWrapperStyles,
      fieldPrefix: GC.FIELD_PREFIX_CARRIER_ASSIGNMENT,
    },
    {
      type: 'asyncselect',
      label: ['titles:mc-number'],
      handler: 'handleCarrierFilter',
      fieldName: GC.FIELD_CARRIER_MC_NUMBER,
      optionGetter: 'handleGetCarrierOptions',
      fieldPrefix: GC.FIELD_PREFIX_CARRIER_ASSIGNMENT,
      inputWrapperStyles: { ...carrierInputWrapperStyles, mr: 0 },
    },
    {
      type: 'asyncselect',
      label: ['titles:terminal'],
      handler: 'handleFieldValue',
      optionGetter: 'handleGetTerminalOptions',
      fieldName: GC.FIELD_CARRIER_TERMINAL_GUID,
      fieldPrefix: GC.FIELD_PREFIX_CARRIER_ASSIGNMENT,
      inputWrapperStyles: { mt: 15, mr: 15, width: 300 },
    },
  ];

  const dispatchedBy = G.isTrue(isEditMode)
    ? {
      label: ['titles:dispatched-by'],
      fieldName: GC.FIELD_DISPATCHED_OR_CANCEL_BY,
      inputWrapperStyles: { ...dispatchedByStyle },
    }
    : {
      type: 'asyncselect',
      handler: 'handleFieldValue',
      label: ['titles:dispatched-by'],
      fieldName: GC.FIELD_DISPATCHED_BY_LOGIN,
      optionGetter: 'handleGetDispatcherOptions',
      inputWrapperStyles: { ...dispatchedByStyle },
    };

  return R.append(dispatchedBy, fields);
};

const dispatcherInputWrapperStyles = {
  mr: 15,
  width: 190,
};

const dispatcherFields = [
  {
    type: 'text',
    label: ['titles:dispatcher-name'],
    fieldName: GC.FIELD_DISPATCH_NAME,
    inputWrapperStyles: dispatcherInputWrapperStyles,
  },
  {
    type: 'phoneNumber',
    label: ['titles:dispatcher-phone'],
    fieldName: GC.FIELD_DISPATCH_PHONE,
    inputWrapperStyles: dispatcherInputWrapperStyles,
  },
];

const firstInputWrapperStyles = {
  mr: 15,
  mb: 30,
  width: 150,
};

const mainCarrierRateFields = [
  {
    type: 'select',
    isRequired: true,
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    label: ['titles:currency', 'Currency'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    isRequired: true,
    options: GC.FIELD_MODE,
    fieldName: GC.FIELD_MODE,
    label: ['titles:mode', 'Mode'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'select',
    options: GC.FIELD_SERVICE_TYPE,
    fieldName: GC.FIELD_SERVICE_TYPE,
    inputWrapperStyles: firstInputWrapperStyles,
    label: ['titles:service-type', 'Service Type'],
  },
  {
    type: 'number',
    fieldName: GC.FIELD_SERVICE_DAYS,
    label: ['titles:service-days', 'Service Days'],
    inputWrapperStyles: firstInputWrapperStyles,
  },
  {
    type: 'number',
    fieldName: GC.FIELD_CUSTOMER_TOTAL,
    label: ['titles:customer-total', 'Customer Total'],
    inputWrapperStyles: { ...firstInputWrapperStyles, mr: 0, width: 145 },
  },
];

const totalCarrierRateFields = [
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: { width: 125 },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:trip-distance', 'Trip Distance'],
  },
  {
    type: 'select',
    inputWrapperStyles: { width: 133, mr: 15 },
    fieldName: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    isRequired: true,
    inputWrapperStyles: { width: 125 },
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:total-weight', 'Total Weight'],
  },
  {
    type: 'select',
    options: DEFAULT_ITEM_WEIGHT_UOM_OPTIONS,
    fieldName: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    inputWrapperStyles: { width: 133, mr: 15 },
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
  {
    type: 'number',
    inputWrapperStyles: { width: 125 },
    fieldName: GC.FIELD_TOTAL_PICKUP_QUANTITY,
    inputStyles: { borderRadius: '4px 0 0 4px' },
    label: ['titles:pickup-quantity', 'Pickup Quantity'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_PACKAGE_TYPE,
    inputWrapperStyles: { width: 133, mr: 0 },
    options: DEFAULT_QUANTITY_RATE_UNIT_OPTIONS,
    inputStyles: { borderRadius: '0 4px 4px 0' },
  },
];

const carrierFleetInputWrapperStyles = {
  mr: 15,
  mb: 30,
  width: 190,
};

const carrierFleetInputWrapperStyles2 = {
  mr: 15,
  width: 190,
};

const carrierFleetFields = [
  {
    type: 'text',
    label: ['titles:truck', 'Truck'],
    inputWrapperStyles: carrierFleetInputWrapperStyles,
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_TRUCK}`,
  },
  {
    type: 'text',
    label: ['titles:trailers', 'Trailers'],
    inputWrapperStyles: carrierFleetInputWrapperStyles,
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_TRAILER}`,
  },
  {
    type: 'text',
    fieldName: GC.FIELD_COMMENTS,
    label: ['titles:comments', 'Comments'],
    inputWrapperStyles: { ...carrierFleetInputWrapperStyles, mr: 0, width: 395 },
  },
  {
    type: 'text',
    inputWrapperStyles: carrierFleetInputWrapperStyles2,
    label: ['titles:primary-driver', 'Primary Driver'],
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_PRIMARY_DRIVER}`,
  },
  {
    type: 'phoneNumber',
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: carrierFleetInputWrapperStyles2,
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_PRIMARY_DRIVER_PHONE}`,
  },
  {
    type: 'text',
    label: ['titles:team-driver', 'Team Driver'],
    inputWrapperStyles: carrierFleetInputWrapperStyles2,
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_SECONDARY_DRIVER}`,
  },
  {
    type: 'phoneNumber',
    label: ['titles:phone', 'Phone'],
    inputWrapperStyles: { ...carrierFleetInputWrapperStyles2, mr: 0 },
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_SECONDARY_DRIVER_PHONE}`,
  },
];

const trackingReferenceInputWrapperStyles = {
  mr: 15,
  width: 190,
};

const trackingReferenceFields = [
  {
    type: 'text',
    label: ['titles:bol-number'],
    inputWrapperStyles: trackingReferenceInputWrapperStyles,
    customDisabledFunction: 'handleDisableBOLNumberField',
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_BOL_NUMBER}`,
  },
  {
    type: 'text',
    label: ['titles:tracking-number'],
    inputWrapperStyles: trackingReferenceInputWrapperStyles,
    customDisabledFunction: 'handleDisableTrackingNumberField',
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_CARRIER_ACCEPT_TRACKING_NUMBER}`,
  },
  {
    type: 'text',
    label: ['titles:pickup-number'],
    inputWrapperStyles: trackingReferenceInputWrapperStyles,
    customDisabledFunction: 'handleDisablePickupNumberField',
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_PICKUP_NUMBER}`,
  },
  {
    type: 'text',
    disabled: true,
    label: ['titles:quote-number'],
    inputWrapperStyles: { ...trackingReferenceInputWrapperStyles, mr: 0 },
    fieldName: `${GC.FIELD_PREFIX_CARRIER_ASSIGNMENT}.${GC.FIELD_QUOTE_NUMBER}`,
  },
];

export {
  dispatcherFields,
  carrierRateReport,
  carrierFleetFields,
  carrierOptionFields,
  carrierRateSettings,
  mainCarrierRateFields,
  carrierRateInitFields,
  dispatcherOptionFields,
  totalCarrierRateFields,
  getCarrierSectionFields,
  trackingReferenceFields,
  dispatcherInfoInitFields,
  carrierAssignmentInitFields,
};
