// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

export default [
  {
    submenu: [],
    action: 'dashboards',
    isSubmenuItem: false,
    iconName: 'dashboards',
    key: 'menu:dashboards',
    andPermissions: [PC.CHART_READ, PC.CHART_WRITE],
    permissions: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
    title: G.getWindowLocale('menu:dashboards', 'Dashboards'),
  },
  {
    submenu: [],
    action: 'analytic',
    isSubmenuItem: false,
    iconName: 'analytic2',
    key: 'menu:analytics',
    andPermissions: [PC.CHART_READ, PC.CHART_WRITE],
    permissions: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
    title: G.getWindowLocale('menu:analytics', 'Analytics'),
  },
  {
    submenu: [],
    isSubmenuItem: false,
    action: 'customerPortal',
    iconName: 'customerPortal2',
    key: 'menu:customer-portal',
    permissions: [PC.CLO_READ, PC.CLO_WRITE],
    title: G.getWindowLocale('menu:customer-portal', 'Customer Portal'),
  },
  {
    submenu: [],
    isSubmenuItem: false,
    action: 'analyticMaps',
    iconName: 'analyticMaps2',
    key: 'menu:analytic-maps',
    permissions: [PC.DASHBOARD_READ, PC.DASHBOARD_WRITE],
    title: G.getWindowLocale('menu:analytic-maps', 'Fleet Map'),
  },
  {
    submenu: [],
    action: 'routeBeta',
    isSubmenuItem: false,
    iconName: 'routesLoads2',
    key: 'menu:dispatch-board-beta',
    title: G.getWindowLocale('menu:dispatch-board-beta', 'Dispatch Board'),
    permissions: [PC.CLO_READ, PC.CLO_WRITE, PC.TEL_READ, PC.TEL_WRITE, PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
  },
  {
    submenu: [],
    iconName: 'driver2',
    isSubmenuItem: false,
    action: 'driversCard',
    key: 'menu:drivers-card-beta',
    permissions: [PC.ROLE_TYPE_GENERAL],
    title: G.getWindowLocale('menu:driver-cards-beta', 'Driver Cards'),
  },
  {
    submenu: [],
    isSubmenuItem: false,
    action: 'availableDrivers',
    iconName: 'renderDriverIcon',
    key: 'menu:available-drivers',
    title: G.getWindowLocale('menu:available-drivers', 'Available Drivers'),
    permissions: [PC.AVAILABLE_DRIVER_LIST_READ, PC.AVAILABLE_DRIVER_LIST_WRITE],
  },
  {
    submenu: [],
    isSubmenuItem: false,
    action: 'loadPlanner',
    iconName: 'routeBuilder2',
    permissions: [PC.TEL_WRITE],
    key: 'menu:load-planner-beta',
    title: G.getWindowLocale('menu:load-planner-beta', 'Load Planner'),
  },
  {
    iconName: 'invoice2',
    key: 'menu:invoices',
    isSubmenuItem: false,
    permissions: [PC.ANY],
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:invoices', 'Invoices'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:customer',
        action: 'customerInvoiceList',
        title: G.getWindowLocale('menu:customer', 'Customer'),
        permissions: [PC.CLO_INVOICE_OVERWRITE_EXECUTE, PC.CLO_INVOICE_WRITE, PC.CLO_INVOICE_READ],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:master-invoice',
        action: 'customerMasterInvoiceList',
        title: G.getWindowLocale('menu:master-invoice', 'Master Invoice'),
        permissions: [PC.CLO_MASTER_INVOICE_READ, PC.CLO_MASTER_INVOICE_WRITE, PC.CLO_MASTER_INVOICE_OVERWRITE_EXECUTE],
      },
      {
        submenu: [],
        key: 'menu:driver',
        isSubmenuItem: true,
        action: 'driverInvoiceList',
        title: G.getWindowLocale('menu:driver', 'Driver'),
        permissions: [PC.TEL_INVOICE_OVERWRITE_EXECUTE, PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_FLEET_INVOICE_READ],
      },
      {
        submenu: [],
        key: 'menu:vendor',
        isSubmenuItem: true,
        action: 'vendorInvoiceList',
        title: G.getWindowLocale('menu:vendor', 'Vendor'),
        permissions: [PC.TEL_INVOICE_OVERWRITE_EXECUTE, PC.TEL_FLEET_INVOICE_WRITE, PC.TEL_FLEET_INVOICE_READ],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:carrier',
        action: 'carrierInvoiceList',
        title: G.getWindowLocale('menu:carrier', 'Carrier'),
        permissions: [PC.TEL_INVOICE_OVERWRITE_EXECUTE, PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_CARRIER_INVOICE_READ],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:service-vendor',
        action: 'serviceVendorInvoice',
        title: G.getWindowLocale('menu:service-vendor', 'Service Vendor'),
        permissions: [PC.TEL_INVOICE_OVERWRITE_EXECUTE, PC.TEL_CARRIER_INVOICE_WRITE, PC.TEL_CARRIER_INVOICE_READ],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:payroll',
        action: 'driverPayrollList',
        title: G.getWindowLocale('menu:payroll', 'Payroll'),
        permissions: [PC.DRIVER_PAYROLL_OVERWRITE_EXECUTE, PC.DRIVER_PAYROLL_WRITE, PC.DRIVER_PAYROLL_READ],
      },
    ],
  },
  {
    isSubmenuItem: false,
    iconName: 'contract2',
    key: 'menu:contracts',
    permissions: [PC.ANY],
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:contracts', 'Contracts'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:customer-contracts',
        action: 'customerContractList',
        permissions: [PC.CARRIER_READ, PC.CARRIER_WRITE],
        title: G.getWindowLocale('menu:customer', 'Customer'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:carrier-contracts',
        action: 'carrierContractList',
        permissions: [PC.CARRIER_READ, PC.CARRIER_WRITE],
        title: G.getWindowLocale('menu:carrier', 'Carrier'),
      },
    ],
  },
  {
    isSubmenuItem: false,
    key: 'menu:templates',
    permissions: [PC.ANY],
    iconName: 'dispatchTemplate2',
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:templates', 'Templates'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:shared-accessorial',
        action: 'sharedAccessorialList',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:shared-accessorial', 'Shared Accessorial'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'doTemplates',
        key: 'menu:do-template',
        permissions: [PC.CLO_WRITE],
        title: G.getWindowLocale('menu:do-template', 'Order'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'routeTemplates',
        key: 'menu:route-template',
        permissions: [PC.TEL_WRITE],
        title: G.getWindowLocale('menu:route-template', 'Route'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        permissions: [PC.TEL_WRITE],
        action: 'loadPlannerTemplate',
        key: 'menu:load-planner-template',
        title: G.getWindowLocale('menu:load-planner-template', 'Load Planner'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:location',
        action: 'locationsList',
        permissions: [PC.LOCATION_TEMPLATE_WRITE],
        title: G.getWindowLocale('menu:location', 'Location'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:container-type',
        action: 'containerTypeList',
        permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
        title: G.getWindowLocale('menu:container-type', 'Container Type'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:container',
        action: 'containerTemplateList',
        permissions: [PC.CONTAINER_TEMPLATE_WRITE],
        title: G.getWindowLocale('menu:container', 'Container'),
      },
      {
        submenu: [],
        key: 'menu:item',
        isSubmenuItem: true,
        action: 'itemsList',
        permissions: [PC.ITEM_TEMPLATE_WRITE],
        title: G.getWindowLocale('menu:item', 'Item'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'contactBook',
        key: 'menu:contact-book',
        permissions: [PC.CONTACT_BOOK_WRITE],
        title: G.getWindowLocale('menu:contact-book', 'Contact Book'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:compensation',
        action: 'compensationTemplateList',
        permissions: [PC.COMPENSATION_TEMPLATE_WRITE],
        title: G.getWindowLocale('menu:compensation', 'Compensation'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'commissionAssignee',
        key: 'menu:commission-assignee',
        permissions: [PC.CHANGE_COMMISSION_ASSIGNMENT_EXECUTE],
        title: G.getWindowLocale('menu:commission-assignee', 'Commission Assignee'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'geoFencingZone',
        key: 'menu:geo-fencing-zone',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:geo-fencing-zone', 'Geo Fencing Zone'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'inspectionTemplates',
        key: 'menu:inspection-templates',
        title: G.getWindowLocale('menu:inspection', 'Inspection'),
        // TODO: change inspection permissions later on
        permissions: [PC.ROLE_LIUBANCHYK_SUPER_ADMIN],
        // permissions: [PC.INSPECTION_TEMPLATE_READ, PC.INSPECTION_TEMPLATE_WRITE],
      },
    ],
  },
  {
    iconName: 'eye2',
    key: 'menu:views',
    isSubmenuItem: false,
    permissions: [PC.ANY],
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:views', 'Views'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'createCLO',
        key: 'menu:create-clo',
        permissions: [PC.CLO_WRITE],
        title: G.getWindowLocale('menu:create-clo', 'Create Order'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:branches',
        action: 'branchList',
        permissions: [PC.BRANCH_READ, PC.BRANCH_WRITE],
        title: G.getWindowLocale('menu:branches', 'Branches'),
      },
      {
        submenu: [],
        key: 'menu:clo',
        action: 'cloList',
        isSubmenuItem: true,
        permissions: [PC.CLO_READ, PC.CLO_WRITE],
        title: G.getWindowLocale('menu:clo', 'Order'),
      },
      {
        submenu: [],
        key: 'menu:tel',
        action: 'telList',
        isSubmenuItem: true,
        permissions: [PC.TEL_READ, PC.TEL_WRITE],
        title: G.getWindowLocale('menu:tel', 'Trip'),
      },
      {
        submenu: [],
        key: 'menu:route',
        isSubmenuItem: true,
        action: 'routeList',
        permissions: [PC.TEL_WRITE],
        title: G.getWindowLocale('menu:route', 'Route'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'routeBuilder',
        key: 'menu:route-builder',
        permissions: [PC.TEL_WRITE],
        title: G.getWindowLocale('menu:route-builder', 'Route Builder'),
      },
      {
        submenu: [],
        key: 'menu:users',
        isSubmenuItem: true,
        action: 'usersList',
        title: G.getWindowLocale('menu:users', 'Users'),
        permissions: [PC.USER_READ, PC.USER_WRITE],
      },
      {
        submenu: [],
        action: 'rolesList',
        isSubmenuItem: true,
        key: 'menu:user-permissions',
        permissions: [PC.ROLES_READ, PC.ROLES_WRITE],
        title: G.getWindowLocale('menu:user-permissions', 'User Permissions'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:carriers',
        action: 'carrierList',
        permissions: [PC.CARRIER_READ, PC.CARRIER_WRITE],
        title: G.getWindowLocale('menu:carriers', 'Carriers'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:service-vendors',
        action: 'serviceVendorList',
        permissions: [PC.SERVICE_VENDOR_READ, PC.SERVICE_VENDOR_WRITE],
        title: G.getWindowLocale('menu:service-vendors', 'Service Vendors'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:references',
        action: 'referenceTypesList',
        permissions: [PC.REFERENCE_TYPE_WRITE],
        title: G.getWindowLocale('menu:references', 'References'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:sequences',
        action: 'sequencesList',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:sequences', 'Sequences'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:report-manager',
        action: 'reportFormatsList',
        permissions: [PC.REPORT_WRITE],
        title: G.getWindowLocale('menu:report-manager', 'Report Manager'),
      },
      {
        submenu: [],
        action: 'chartList',
        isSubmenuItem: true,
        key: 'menu:views:charts',
        permissions: [PC.CHART_READ, PC.CHART_WRITE],
        title: G.getWindowLocale('menu:views:charts', 'Charts'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'currencyExchangeRate',
        key: 'menu:currency-exchange-rate',
        permissions: [PC.ROLE_TYPE_GENERAL],
        title: G.getWindowLocale('menu:currency-exchange-rate', 'Currency Exchange Rate'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        permissions: [PC.ANY],
        action: 'averageFuelPrice',
        key: 'menu:average-fuel-price',
        title: G.getWindowLocale('menu:average-fuel-price', 'Average Fuel Prices'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'dispatchingGroup',
        key: 'menu:dispatching-group',
        permissions: [PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE],
        title: G.getWindowLocale('menu:dispatching-group', 'Dispatching Group'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'chargeReports',
        key: 'menu:charge-reports',
        title: G.getWindowLocale('menu:charge-reports', 'Charge Reports'),
        permissions: [PC.CLO_READ, PC.CLO_WRITE, PC.TEL_READ, PC.TEL_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'taskManagement',
        key: 'menu:task-management',
        title: G.getWindowLocale('menu:task-management', 'Task Management'),
        permissions: [PC.TASK_READ, PC.TASK_WRITE, PC.TASK_BOARD_READ, PC.TASK_BOARD_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'titles:claims',
        action: 'claimManagement',
        permissions: [PC.CLAIM_READ, PC.CLAIM_WRITE],
        title: G.getWindowLocale('titles:claims', 'Claims'),
      },
    ],
  },
  {
    key: 'menu:dispatch',
    isSubmenuItem: false,
    permissions: [PC.ANY],
    iconName: 'routesLoads3',
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:dispatch', 'Dispatch'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:dispatch-edi',
        action: GC.MENU_ACTION_DISPATCH_EDI,
        permissions: [PC.CLO_EDI_READ, PC.CLO_EDI_WRITE],
        title: G.getWindowLocale('menu:dispatch-edi', 'EDI'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:dispatch-carrier-edi',
        action: GC.MENU_ACTION_DISPATCH_CARRIER_EDI,
        permissions: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
        title: G.getWindowLocale('menu:dispatch-carrier-edi', 'Carrier EDI'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:dispatch-order-api',
        action: GC.MENU_ACTION_DISPATCH_ORDER_API,
        title: G.getWindowLocale('menu:dispatch-order-api', 'Order API'),
        permissions: [PC.PUBLIC_API_ORDER_READ, PC.PUBLIC_API_ORDER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:dispatch-order-quotes',
        action: GC.MENU_ACTION_DISPATCH_ORDER_QUOTES,
        title: G.getWindowLocale('menu:dispatch-order-quotes', 'Quotes'),
        permissions: [PC.ORDER_QUOTE_READ, PC.ORDER_QUOTE_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        permissions: [PC.CLO_READ, PC.CLO_WRITE],
        key: 'menu:dispatch-order-external-quotes',
        action: GC.MENU_ACTION_DISPATCH_EXTERNAL_QUOTES,
        title: G.getWindowLocale('menu:dispatch-order-external-quotes', 'External Quotes'),
      },
    ],
  },
  {
    isSubmenuItem: false,
    permissions: [PC.ANY],
    iconName: 'backgroundProcesses2',
    key: 'menu:background-processes',
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:background-processes', 'Background Processes'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'carrierRatingReport',
        key: 'menu:carrier-rating-report',
        permissions: [PC.CARRIER_RATE_WRITE],
        title: G.getWindowLocale('menu:carrier-rating-report', 'Carrier Report'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'importReport',
        key: 'menu:import-report',
        permissions: [PC.IMPORT_EXECUTE],
        title: G.getWindowLocale('menu:import-report', 'Import Report'),
      },
      {
        submenu: [],
        action: 'rateShop',
        isSubmenuItem: true,
        key: 'menu:rate-shop',
        permissions: [PC.RATE_SHOP_EXECUTE],
        title: G.getWindowLocale('menu:rate-shop', 'Rate Shop'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        permissions: [PC.TEL_WRITE],
        action: 'routeMassCreateReport',
        key: 'menu:route-mass-create-report',
        title: G.getWindowLocale('menu:route-mass-create-report', 'Route Mass Create Report'),
      },
    ],
  },
  {
    submenu: [],
    action: 'imports',
    key: 'menu:imports',
    isSubmenuItem: false,
    iconName: 'importDocument2',
    permissions: [PC.IMPORT_EXECUTE],
    title: G.getWindowLocale('menu:imports', 'Imports'),
  },
  {
    submenu: [],
    isSubmenuItem: false,
    iconName: 'loadSearch2',
    action: 'externalloadboard',
    key: 'menu:external-load-search',
    permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
    title: G.getWindowLocale('menu:external-load-search', 'Load Search'),
  },
  {
    key: 'menu:fleet',
    isSubmenuItem: false,
    permissions: [PC.ANY],
    iconName: 'truckGroup2',
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:fleet', 'Fleet'),
    submenu: [
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet:driver',
        action: 'fleetDriversList',
        title: G.getWindowLocale('menu:fleet:driver', 'Driver'),
        permissions: [PC.FLEET_DRIVER_READ, PC.FLEET_DRIVER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet:truck',
        action: 'fleetTrucksList',
        title: G.getWindowLocale('menu:fleet:truck', 'Truck'),
        permissions: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet:trailer',
        action: 'fleetTrailersList',
        title: G.getWindowLocale('menu:fleet:trailer', 'Trailer'),
        permissions: [PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet-service-issue',
        action: 'fleetServiceIssuesList',
        title: G.getWindowLocale('menu:fleet-service-issue', 'Issue'),
        permissions: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE, PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:equipment-service',
        action: 'fleetEquipmentServicesList',
        title: G.getWindowLocale('menu:equipment-service', 'Equipment Service'),
        permissions: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE, PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet-shared-component',
        action: 'fleetSharedComponentList',
        title: G.getWindowLocale('menu:fleet-shared-component', 'Shared Component'),
        permissions: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE, PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:work-order',
        action: 'workOrderList',
        title: G.getWindowLocale('menu:work-order', 'Work Order'),
        permissions: [PC.FLEET_TRUCK_READ, PC.FLEET_TRUCK_WRITE, PC.FLEET_TRAILER_READ, PC.FLEET_TRAILER_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:fleet-vendor',
        action: 'fleetVendorList',
        title: G.getWindowLocale('menu:fleet-vendor', 'Vendor'),
        permissions: [PC.FLEET_VENDOR_READ, PC.FLEET_VENDOR_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:payroll-accessorial',
        action: 'payrollAccessorialList',
        permissions: [PC.FLEET_DRIVER_WRITE, PC.FLEET_VENDOR_WRITE],
        title: G.getWindowLocale('menu:payroll-accessorial', 'Payroll Accessorial'),
      },
      {
        submenu: [],
        action: 'fuelCards',
        isSubmenuItem: true,
        key: 'menu:fuel-cards',
        title: G.getWindowLocale('menu:fuel-cards', 'Fuel Cards'),
        permissions: [PC.FLEET_FUEL_CARD_READ, PC.FLEET_FUEL_CARD_WRITE],
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'tollCharges',
        key: 'menu:toll-charges',
        permissions: [PC.TOLL_CHARGE_READ, PC.TOLL_CHARGE_WRITE],
        title: G.getWindowLocale('menu:toll-charges', 'Toll Charges'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'iftaReport',
        key: 'menu:ifta-report',
        permissions: [PC.IFTA_EXECUTE],
        title: G.getWindowLocale('menu:ifta-report', 'IFTA Report'),
      },
    ],
  },
  {
    iconName: 'gear2',
    isSubmenuItem: false,
    permissions: [PC.ANY],
    key: 'menu:configurations',
    action: 'openViewsSubitemRequest',
    title: G.getWindowLocale('menu:configurations', 'Configurations'),
    submenu: [
      {
        submenu: [],
        key: 'menu:ui',
        action: 'configUI',
        isSubmenuItem: true,
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:ui', 'UI'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configRating',
        key: 'menu:configuration:rating',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:rating', 'Rating'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configInvoices',
        key: 'menu:configuration:invoices',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:invoices', 'Invoices'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configCarrier',
        key: 'menu:configuration:carrier',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:carrier', 'Carrier'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configServiceVendor',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:service-vendor',
        title: G.getWindowLocale('menu:service-vendor', 'Service Vendor'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'menu:general',
        action: 'configGeneral',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:general', 'General'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configCLO',
        key: 'menu:configuration:clo',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:clo', 'Order'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configTEL',
        key: 'menu:configuration:tel',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:tel', 'Trip'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        key: 'titles:claims',
        action: 'configClaimManagement',
        permissions: [PC.CLAIM_READ, PC.CLAIM_WRITE],
        title: G.getWindowLocale('titles:claims', 'Claims'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configTaskManagement',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:task-management',
        title: G.getWindowLocale('menu:task-management', 'Task Management'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'fleetGeneral',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:fleet-general',
        title: G.getWindowLocale('menu:fleet-general', 'Fleet General'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configDriver',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:fleet-driver',
        title: G.getWindowLocale('menu:fleet-driver', 'Fleet Driver'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configTruck',
        key: 'menu:configuration:fleet-truck',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:fleet-truck', 'Fleet Truck'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configTrailer',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:fleet-trailer',
        title: G.getWindowLocale('menu:fleet-trailer', 'Fleet Trailer'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configCommunication',
        permissions: [PC.CONFIGURATIONS_WRITE],
        key: 'menu:configuration:communication',
        title: G.getWindowLocale('menu:communication', 'Communication'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configIntegration',
        key: 'menu:configuration:integration',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:integration', 'Integration'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configTemplates',
        key: 'menu:configuration:template',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:template', 'Templates'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configPassword',
        key: 'menu:configuration:password',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:password', 'Password'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'stylingConfig',
        permissions: [PC.STYLING_EXECUTE],
        key: 'menu:configurations:styling',
        title: G.getWindowLocale('menu:configurations:styling', 'Styling'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configMobileApp',
        key: 'menu:configuration:mobile-app',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:mobile-app', 'Mobile Application'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configMaster',
        key: 'menu:configuration:master',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:master', 'Optional'),
      },
      {
        submenu: [],
        isSubmenuItem: true,
        action: 'configAudit',
        key: 'menu:configurations:audit',
        permissions: [PC.CONFIGURATIONS_WRITE],
        title: G.getWindowLocale('menu:configurations:audit', 'Audit'),
      },
    ],
  },
];
